import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FirestoreService {
  constructor(private afs: AngularFirestore) {}
  private pedidosSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public angularFirestore = this.afs

  readData(collection, document) {
    let itemsDocument: AngularFirestoreDocument<any>;
    itemsDocument = this.afs.collection<any>(collection).doc(document);
    return itemsDocument.valueChanges();
  }

  //LER UMA COLEÇÃO/LISTA DO BANCO DE DADOS
  readCollection(link) {
    let itemsCollection: AngularFirestoreCollection<any>;
    itemsCollection = this.afs.collection<any>(link);
    return itemsCollection.valueChanges();
  }

  //LER UMA COLEÇÃO/LISTA DO BANCO DE DADOS
  readCollectionWhere(link, where, id) {
    let itemsCollection: AngularFirestoreCollection<any>;
    itemsCollection = this.afs.collection<any>(link, (ref) => ref.where(where, "==", id)
    );
    return itemsCollection.valueChanges()
  }
  getData(link: string, where: string, id: string): Observable<any[]> {
    const itemsCollection: AngularFirestoreCollection<any> = this.afs.collection<any>(
      link,
      (ref) => ref.where(where, '==', id)
    );
    itemsCollection.snapshotChanges().pipe(
      map((changes) => {
        const pedidos = changes.map((change) => {
          const data = change.payload.doc.data();
          const id = change.payload.doc.id;
          return { id, ...data };
        });
        return pedidos;
      })
    ).subscribe((pedidos) => {
      this.pedidosSubject.next(pedidos);
    });

    return this.pedidosSubject;

   }
  getDataUpdate(link: string, where: string, id: string): Observable<number|any[]> {
    const itemsCollection: AngularFirestoreCollection<any> = this.afs.collection<any>(
      link,
      (ref) => ref.where(where, '==', id)
    );
    return itemsCollection.valueChanges()
    .pipe(
      map((array) => array ),
      distinctUntilChanged(),
       // Emite apenas quando o tamanho do array muda
    );
  }
  getDataUpdateOrder(link: string, where: string, id: string): Observable<number|any[]> {
    const itemsCollection: AngularFirestoreCollection<any> = this.afs.collection<any>(
      link,
      (ref) => ref.where(where, '==', id)
    );
    return itemsCollection.valueChanges()
    .pipe(
      map((array) => array ),
      distinctUntilChanged(),
       // Emite apenas quando o tamanho do array muda
    );
  }

  //LER UMA COLEÇÃO/LISTA DO BANCO DE DADOS
  readCollectionOrder(link, where, order, id) {
    let itemsCollection: AngularFirestoreCollection<any>;
    itemsCollection = this.afs.collection<any>(link, (ref) =>
      ref.where(where, "==", id).orderBy(order)
    );
    return itemsCollection.valueChanges();
  }

  //ADICIONAR UM NOVO ITEM A UMA COLEÇÃO NO BANCO DE DADOS
  addIntoCollection(collection, data) {
    let itemsCollection: AngularFirestoreCollection<any>;
    let items: Observable<any[]>;

    itemsCollection = this.afs.collection<any>(collection);
    itemsCollection.add(data);
  }

  //ADICIONAR UM NOVO ITEM A UMA COLEÇÃO NO BANCO DE DADOS
  addIntoDocument(collection, document, data) {
    // console.log("addIntoDocumet data", data);
    let itemsCollection: AngularFirestoreDocument<any>;

    itemsCollection = this.afs.collection<any>(collection).doc(document);
    return itemsCollection.set(data);
  }

  //ADICIONAR UM NOVO ITEM A UMA COLEÇÃO NO BANCO DE DADOS
  updateIntoDocumet(collection, document, data) {
    // console.log(data)
    let itemsCollection: AngularFirestoreDocument<any>;

    itemsCollection = this.afs.collection<any>(collection).doc(document);
    return itemsCollection.update(data);
  }

  //ATUALIZAR UM DOCUMENTO NO BANCO DE DADOS
  updateIntoCollectionItem(collection, document, data) {
    let itemsCollection: AngularFirestoreCollection<any>;

    itemsCollection = this.afs.collection<any>(collection);
    return itemsCollection.doc(document).update(data);
  }

  //ATUALIZAR UMA PROPRIEDADE DE UMA COLEÇÃO SEM A NECESSIDADE DO ID DO DOCUMENTO
  updateIntoCollectionWhere(
    collection: string,
    property: string,
    value: any,
    newProperty: string,
    newValue: any
  ) {
    this.afs
      .collection(collection)
      .ref.where(property, "==", value)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const updatedData = {};
          updatedData[newProperty] = newValue;
          doc.ref.update(updatedData);
        });
      })
      .catch((error) => {
        console.error(
          `Erro ao atualizar a propriedade "${newProperty}" da coleção "${collection}" para o valor "${newValue}":`,
          error
        );
      });
  }

  //REMOVER UM DOCUMENTO NO BANCO DE DADOS
  deleteDocument(collection, document) {
    let itemsCollection: AngularFirestoreDocument<any>;

    itemsCollection = this.afs.collection<any>(collection).doc(document);
    itemsCollection.delete();
  }

  // getDataUpdate(link, where, id): Observable<DocumentChangeAction<unknown>[]> {
  //   return this.afs
  //     .collection(link, (ref) => ref.where(where, "==", id))
  //     .snapshotChanges();
  // }

  createId() {
    return this.afs.createId();
  }
}
