import * as XLSX from 'xlsx';

export function exportJsonToSheet(
  json: any[],
  sheetName: string,
  fileName: string
) {
  const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

  const workBook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);

  XLSX.writeFile(workBook, fileName);
}
