import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Moment } from "moment";
import { Subscription } from "rxjs";
import { debounceTime, delay, switchMap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { DateService } from "src/app/services/date.service";
import { FirestoreService } from "src/app/services/firestore.service";
import { GeneralService } from "src/app/services/general.service";
import { FirebaseOrder, Store, StoreApp } from "src/app/services/interfaces.service";
import {
  PagarMeOrderResponse,
  PagarMeOrderResponseData,
  PagarmeService,
} from "src/app/services/pagarme.service";
import { UiService } from "src/app/services/ui.service";
import { exportJsonToSheet } from "src/app/utils/export-excel";

@Component({
  selector: "app-analyze-orders",
  templateUrl: "./analyze-orders.component.html",
  styleUrls: ["./analyze-orders.component.scss"],
})
export class AnalyzeOrdersComponent implements OnInit {
  public startDate: string;
  public endDate: string;
  public formDate: FormGroup
  public store: StoreApp;
  public pages: number[];
  public pageSize: number = 12;
  public totalPages: number;
  public currentPage: number;
  public totalPagesToShow: number = 3;
  public displayedOrders: FirebaseOrder[] = [];
	public status: 'processing' | 'preparing' | 'waiting-delivery' | 'in-delivery' | 'completed' | 'canceled' = null;
	public title:string = '';
  public orders: FirebaseOrder[] = [];
  private ordersMock: FirebaseOrder[] = [];
  public allStatus = {
		'aprovado': "Novo",
		'preparing': "Em preparo",
		'waiting-delivery': "Aguardando entrega",
		'in-delivery': "Saiu para entrega",
		'completed': "Entregue",
		'canceled': "Cancelado"
	}

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private pgService: PagarmeService,
    public general: GeneralService,
    private ui: UiService,
    public dateService: DateService,
    private formBuild: FormBuilder,

  ) {
    this.formDate = this.formBuild.group({
      startDate: ["", [Validators.required, Validators.maxLength(10)]],
      endDate: ["", [Validators.required, Validators.maxLength(10)]],
    });
  }

  ngOnInit() {
    this.loadOrders()
  }
  showPage(page: number) {
    // console.log(page)
    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = page;
    const startIndex = (page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedOrders = this.orders.slice(startIndex, endIndex);

    const halfTotalPagesToShow = Math.floor(this.totalPagesToShow / 2);
    const startPage = Math.max(page - halfTotalPagesToShow, 1);
    const endPage = Math.min(
      startPage + this.totalPagesToShow - 1,
      this.totalPages
    );
    this.pages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    // console.log(this.totalPages);
    // console.log(this.pages);

  }

  checkProduct(){
// console.log('teste')
  }
  private loadPagination(): void {
    // console.log('teste')
    // console.log(this.orders)
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedOrders = this.orders?.slice(startIndex, endIndex);
  }

  private loadOrders(): Promise<void> {
    return new Promise(async (resolve) => {
      const storeId = await this.authService.getStoreId();
      let subs: Subscription = this.firestoreService
        .readCollection("pedidos").pipe(debounceTime(500))
        .subscribe((pedidos: FirebaseOrder[]) => {
          this.ordersMock = this.general.sortOrdersByDate(pedidos);
          // console.log(pedidos)
          // get customer details, like cpf, address, name ...
          this.ordersMock.map((order) => {
            if (order) {
              return this.pgService
                .getOrders(order.customerId, { code: order.code }).pipe(debounceTime(500))
                .subscribe((ordersResponse: PagarMeOrderResponse) => {
                  const data: PagarMeOrderResponseData[] = ordersResponse?.data;
                  // console.log(data)
                  order["shipping"] = {
                    address: data[0]?.shipping?.address,
                    name: data[0]?.customer.name,
                    documentType: data[0]?.customer.document_type,
                    document: data[0]?.customer.document,
                  };
                });
            }
          });
          subs.unsubscribe();
          this.loadData();
          this.loadPagination();
          this.loadStores()
          resolve();
        });
    });
  }



  loadStores(){
    this.displayedOrders.map((item)=>{
      this.firestoreService.readData('stores',item.vendorId).pipe(debounceTime(0)).subscribe((data)=>{
        // console.log(data)
        this.store = data;
      })
    })
  }

	loadData() {
		this.orders = [...this.ordersMock];
      this.title = "Todos os Pedidos";

		// TODO Load orders
		// Filter orders TODO REMOVE (ORDERS MUST ALREADY BE FILTERED)
		// if (this.status && this.status === 'processing') {
		// 	this.orders = this.ordersMock.filter(order => order.status === 'aprovado')
		// } else {
		// 	// console.log('this.status', this.status)
		// 	this.orders = this.ordersMock.filter(order => order['statusOrder'] === this.status);
		// }

		// Set up pagination
    this.totalPages = Math.ceil(this.orders.length / this.pageSize);
    this.pages = Array(this.totalPages).fill(0).map((x, i) => i + 1);
    this.showPage(1);
	}
  onExportClicked(): void {
    this.export(this.displayedOrders);
  }
  onExportAllClicked(): void {
    this.firestoreService.readCollection("pedidos").subscribe((data) => {
      this.export(data);
    });
  }
  export(pedidos: any[]): void {
    if (pedidos) {
      const rows = pedidos.map((row) => {
        return {
          Cliente: row.shipping?.name,
          Loja: this.store?.name,
          ID: row.code,
          Status: row['statusOrder'] && row.status ? this.allStatus[row['statusOrder']] : 'Novo',
          Data: this.dateService.stringToDateFormatted(row.date, false) ,
          Valor : this.formatPrice(this.sumOfProps(row.products, 'total').toFixed(2)),

        };
      });
      exportJsonToSheet(rows, 'Pedidos', 'pedidos.xlsx');
      this.ui.presentToast("Exportação concluida com sucesso!",3000,"top","success" );
    } else {
      this.ui.presentToast("Erro ao exporta os pedidos!",3000,"top","warning" );
    }
  }
  prevPage() {
    this.showPage(this.currentPage - 1);
  }

  nextPage() {
    this.showPage(this.currentPage + 1);
  }

  goToPage(page: number) {
    this.showPage(page);
  }

  changePageSize(value: number): void {
    this.pageSize = value as 12 | 24 | 30 | 40 | 50;
    this.loadOrders();
  }
  sumOfProps(array: any[], prop: string): number {
		return this.general.sumOfProps(array, prop);
	}

  handleSearch(event: Event): void{
		const target = event.target as HTMLInputElement;
		const query = target.value;
		// console.log(query)
    if(query.length === 0){
      this.loadOrders()
    }else{
      this.displayedOrders = this.productsFilter(query)
    }
	}

	productsFilter(query: string){
		return this.displayedOrders.filter(item => this.strFormatter(item.shipping.name).indexOf(this.strFormatter(query)) > -1);
	}
	strFormatter(str: string){
		return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
	}
	formatPrice(value: string, locale: string = 'pt-BR', minFractionDigits: number = 2, maxFractionDigits: number = 2): string {
		return this.general.formatPrice(value, locale, minFractionDigits, maxFractionDigits)
	}

  filterRegistros() {
    this.displayedOrders = this.displayedOrders.filter((registro) => {
      const dataRegistro = this.dateService.stringToDateFormatted(registro.date, false)
      const startDate = this.formDate.get('startDate').value;
      const endDate = this.formDate.get('endDate').value;
      // console.log(dataRegistro)
      // console.log(startDate)
      // console.log(endDate)
      // console.log(this.displayedOrders)
      return dataRegistro >= startDate && dataRegistro <= endDate;
    });
    // console.log(this.displayedOrders);
    this.totalPages = Math.ceil(this.displayedOrders.length / this.pageSize);
    this.pages = Array(this.totalPages).fill(0).map((x, i) => i + 1);
    // this.showPage(1);
  }
  filterReset(){
    this.formDate.reset()
    this.loadOrders();
  }
}
