import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as L from "leaflet";
// import { MapComponent as LeafletMap } from 'leaflet';
import { RangeCustomEvent } from "@ionic/angular";
import { RangeValue } from "@ionic/core";

const iconUrl = "assets/icon/icon-logo.png";
const iconDefault = L.icon({
  iconUrl: iconUrl,
  iconSize: [60, 60],
  iconAnchor: [20, 40],
  popupAnchor: [-3, -76],
});
// L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  @Output() closeModal = new EventEmitter<{close: boolean, data: string | number}>();
  @Output() deliveryDistance = new EventEmitter<string | number>();

  @Input() distance: number;
  @Input() lat: any
  @Input() long: any;
  public map: any;
  public  disntanceInKm: number;
  circle: any;

  constructor() {
  }
  isModalOpen = false;

  close(isOpen: boolean) {
    this.closeModal.emit({close: isOpen, data: this.distance});
  }

  ngOnInit() {
    setTimeout(() => {
      this.createMap();
      this.addMarker(this.lat, this.long);
    }, 1000);
  }

  createMap() {
    this.map = L.map("map").setView([this.lat, this.long], 90);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "Map data &copy; OpenStreetMap contributors",
    }).addTo(this.map);
  }

  addMarker(lat: number, lng: number) {
  this.disntanceInKm = this.distance * 1000
    L.marker([lat, lng], { icon: iconDefault })
      .addTo(this.map)
    this.circle = L.circle([lat, lng], {
      radius: this.disntanceInKm,
      color: "#bbed50",
    }).addTo(this.map);
  }

  updateCircle() {
    if (this.circle) {
      this.circle.setRadius(this.distance * 1000);
      this.deliveryDistance.emit(this.distance);
    }
  }
}
