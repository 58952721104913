import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-date',
  templateUrl: './modal-date.component.html',
  styleUrls: ['./modal-date.component.scss'],
})
export class ModalDateComponent implements OnInit {
  @Input() data: string;

  currentDate: string
  constructor(private modalController: ModalController) {
    this.currentDate = new Date().toISOString()
   }

  ngOnInit() {
  }

  editDailySpecial(event){
    const data = {
      date: event.target.value,
    }
    this.modalController.dismiss(data);
  }

  closeModal(){
    this.modalController.dismiss()
  }

}
