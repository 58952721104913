import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-error-form',
  templateUrl: './message-error-form.component.html',
  styleUrls: ['./message-error-form.component.scss'],
})
export class MessageErrorFormComponent implements OnInit {

  @Input() message: string;
  constructor() { }

  ngOnInit() {}

}
