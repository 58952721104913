import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Bank_Account, Recipients } from '../Interfaces/Recipients';

@Injectable({
	providedIn: 'root'
})
export class PagarmeService {

	// Http options to force no cache use
	private options = {
		headers: new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
			'Pragma': 'no-cache',
			'Expires': '0'
		})
	}

	constructor(
		private http: HttpClient,
		private authService: AuthService,
	) {}

	private apiLocation: string = 'https://pg.ecowe.co/payment/'; // TODO ADD REAL URL LOCATION
	private api_url_base: string = 'https://api.pagarme.ecowe.co/'; 

	// TODO ADD STORE CODE TO REQUEST SPECIFIC ORDERS
	/**
	 * Get orders from a customer
	 * @param store_code The store code ID to filter in API (TODO NOT IMPLEMENTED)
	 * @param optional Optional parameters to be use in query
	 */
	public getOrders(customer_id: string, optional?:{code?:string, status?:string, created_since?:Date, created_until?:Date, page?:number, size?:number}): Observable<Object> {
		// let url:string = this.apiLocation + 'orders?customer_id=' + customer_id;
		let url:string = `${this.api_url_base}orders?customer_id=${customer_id}`;

		if (optional) {
			Object.keys(optional).map(key => {
				url = url + "&" + key + "=" + optional[key];
				// use &amp; if ampersand call for it
			});
		}

		return this.http.get(url, this.options);
	}

	/**
	 * Get details from a customer
	 * @param customer_id Is the aquired customer_id from Pagar.me system
	 */
	public getCustomer(customer_id: string): Observable<Object> {
		// let url:string = `${this.api_url_base}customers/${customer_id}`
		let url:string = this.apiLocation + `customers/${customer_id}`

		return this.http.get(url)
	}

	/**
	 * Get orders from a customer
	 * @param store_code The store code ID to filter in API (TODO NOT IMPLEMENTED)
	 * @param optional Optional parameters to be use in query
	 * @returns Observable<Object>
	 */
	public getCharges(code: string, optional?:{customer_id?:string, status?:string, payment_method?:string, order_id?:string, created_since?:Date, created_until?:Date, page?:number, size?:number}): Observable<Object> {
		// let url:string = `${this.api_url_base}charges?code=${code}`;
		let url:string = this.apiLocation + 'charges?code=' + code;
		
		if (optional) {
			Object.keys(optional).map(key => {
				url = url + "&" + key + "=" + optional[key];
				// use &amp; if ampersand call for it
			});
		}

		return this.http.get(url, this.options);
	}

	public createRecipients(recipients: Recipients): Observable<Recipients>{
		// return this.http.post<Recipients>(`${this.api_url_base}recipients`, recipients);
		return this.http.post<Recipients>(`${this.apiLocation}recipients`, recipients);
	}

	public getRecipientsById(recipient_id: string): Observable<Recipients>{
		// return this.http.get<Recipients>(`${this.api_url_base}recipients/${recipient_id}`)
		return this.http.get<Recipients>(`${this.apiLocation}recipients/${recipient_id}`)
	}

	public patchBankAccount(recipient_id: string, bank_account: {bank_account: Bank_Account}): Observable<any>{
		// return this.http.patch<Recipients>(`${this.api_url_base}recipients/${recipient_id}/default-bank-account`, bank_account)
		return this.http.patch<Recipients>(`${this.apiLocation}recipients/${recipient_id}/default-bank-account`, bank_account)
	}

	public getReversal(id: string): Observable<any>{
		// return this.http.delete(`${this.api_url_base}charges/${id}`)
		return this.http.delete(`${this.apiLocation}charges/${id}`)
	}

	private getHttpOptions(): Promise<{headers: HttpHeaders}> {
		return new Promise((resolve, reject) => {
			this.authService.isLogged().subscribe(li => {
				li.getIdToken().then((token)=> {
					resolve({
						headers: new HttpHeaders({
							'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
							'Pragma': 'no-cache',
							'Expires': '0',
							'Authorization': token
						})
					});								
				});
			});
		});
	}
}

type AcceptedPayments = PagarMeCartao | PagarMeVoucher;

/**
 * @param customer_id Is the aquired customer_id from Pagar.me system
 */
export interface PagarMeOrder {
	//customer: PagarMeCustomer,
	customer_id: string,
	items: PagarMeItem[],
	payments: AcceptedPayments[],
	shipping?: PagarMeShipping,
	metadata?: PagarMeMetadata
}

/**
 * @param name Nome do cliente.
 * @param type Tipo de cliente. Valores possíveis: individual e company.
 * @param email E-mail do cliente.
 * @param code Código de referência do cliente no sistema da loja.
 * @param document CPF, CNPJ, PASSAPORTE do cliente.
 * @param gender Sexo do cliente. Valores possíveis: male ou female.
 * @param birthdate Data de nascimento do cliente. Formato mm/dd/aa
 */
export interface PagarMeCustomer {
	name: string,
	type: 'individual' | 'company',
	email?: string,
	code?: string
	document: string,
	document_type: 'CPF' | 'CNPJ' | 'PASSPORT',
	gender?: 'male' | 'female',
	address?: PagarMeAddress,
	phones: PagarMePhone,
	birthdate?: Date,
	metadata?: PagarMeMetadata
}

interface PagarMeMetadata {
	[key: string]: string | string[]
}

interface PagarMeKeyValue {
	[key: string]: string | number
}

export interface PagarMePhone {
	home_phone?: PagarMeDefaultPhone,
	mobile_phone?: PagarMeDefaultPhone
}

/**
 * @param country_code Código do País (Apenas numérico).
 * @param area_code Código da área (Apenas numérico)..
 * @param number Número do telefone (Apenas numérico)..
 */
export interface PagarMeDefaultPhone {
	country_code: string,
	area_code: string,
	number: string
}

/**
 * @param code Código do item no sistema da loja.
 */
export interface PagarMeItem {
	amount: number,
	description: string,
	quantity: string,
	code?: string
}

interface PagarMeShipping {
	amount: number,
	recipient_name: string,
	recipient_phone: string,
	address: PagarMeAddress,
	description: string
}

/**
 * @param zip_code CEP. Max: 16 caracteres.
 * @param state Código do estado no formato ISO 3166-2.
 * @param country Código do país no formato ISO 3166-1 alpha-2.
 * @param line_1 Dados principais do endereço. Neste campo deve ser informado Número, Rua, Bairro, nesta ordem e separados por vírgula.
 * @param line_2 Dados complementares do endereço. Neste campo pode ser informado complemento, referências.
 * @param city Cidade. Max: 64 caracteres.
 */
export interface PagarMeAddress {
	country: 'BR',
	state: string,
	city: string,
	zip_code: string,
	line_1: string,
	line_2: string
}

/**
 * @param credit_card.installments Quantidade de parcelas
 * @param credit_card.statement_descriptor Texto exibido na fatura do cartão. Max: 22 
 */
export interface PagarMeCartao {
	payment_method: 'credit_card'
	credit_card: {
		installments: number,
		card_id: string
		statement_descriptor?: string,
	}
}

interface PagarMeVoucher {
	payment_method: 'voucher'
	card: {
		number: string,
		holder_name: string,
		holder_document: string,
		exp_month: number,
		exp_year: number,
		cvv: string
	}
}

/**
 * @param number Número do cartão. Entre 13 e 19 caracteres
 * @param holder_name Nome do portador como está impresso no cartão. Máximo de 64 caracteres (Caracteres especiais e números não são aceitos)
 * @param holder_document CPF ou CNPJ do portador do cartão. Obrigatório caso o tipo do cartão seja voucher (bandeiras VR ou Sodexo).
 * @param exp_month Mês de validade do cartão. Valor entre 1 e 12 (inclusive)
 * @param exp_year Ano de validade do cartão. Formatos yy ou yyyy. Ex: 23 ou 2023.
 * @param cvv Código de segurança do cartão. O campo aceita 4 ou 3 caracteres, variando por bandeira.
 * @param label Indica a label do cartão
 * @param token Pode ser enviado no lugar dos dados do cartão, caso este já tenha sido tokenizado previamente
 */
export interface PagarMeCartaoDados {
	number: string,
	exp_month: number,
	exp_year: number,
	cvv: string,
	brand?: string,
	holder_name?: string,
	holder_document?: string,
	label?: string,
	billing_address?: PagarMeAddress
}

/**
 * Interface to use when you have the card token
 * @param token The token ID. Get with `createToken()`
 */
export interface PagarMeCartaoToken {
	token: string,
	billing_address?: PagarMeAddress,
}

export interface PagarMeToken {
	type: string,
	card: PagarMeCartaoDados
}

export interface PagarMeCartaoResposta {
	data: PagarMeCartaoRespostaData[],
	paging: {
		next: string,
		total: number
	}
}

export interface PagarMeCartaoRespostaData {
	brand: string,
	created_at: string,
	exp_month: number,
	exp_year: number,
	first_six_digits: string,
	holder_name: string,
	icon: string,
	id: string,
	last_four_digits: string,
	label: string,
	status: string,
	type: string,
	updated_at: string
}

export interface PagarMeOrderResponse {
	data: PagarMeOrderResponseData[],
	paging: {
		next: string,
		total: number
	}
}

interface PagarMeCharges {
	amount: number
	code: string
	created_at: string
	currency: string
	customer: PagarMeCustomerResponse
	id: string
	payment_method: string
	status: string
	updated_at: string
}

interface PagarMeCustomerResponse {
	code: string
	created_at: string
	delinquent: boolean
	email: string
	id: string
	name: string
	phones: PagarMePhone
	updated_at: string
	document_type: string
	document: string
}

export interface PagarMeOrderResponseData {
	amount: number
	charges: PagarMeCharges[]
	closed: boolean
	closed_at: string
	code: string
	created_at: string
	currency: string
	customer: PagarMeCustomerResponse
	id: string
	items: {
		amount: number
		code: string
		created_at: string
		description: string
		id: string
		quantity: number
		status: string
		type: string
		updated_at: string
	}[]
	shipping: {
		address: PagarMeAddress
		description: string
		recipient_name: string
		recipient_phone: string
	}
	status: "pending" | "paid" | "canceled" | "failed";
	statusOrder?: string;
	updated_at: string
	metadata?: PagarMeKeyValue
}