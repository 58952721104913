import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class UiService {
	colorToast = {
		'success': 'green',
		'warning': 'yellow',
		'failed': 'danger'
	}

	iconToast = {
		'success': 'checkmark-circle',
		'warning': 'alert-circle',
		'failed': 'close-circle'
	}

	constructor(
		private alertController: AlertController,
		private loading: LoadingController,
		private toastController: ToastController
	) { }	

	private toast: HTMLIonToastElement;

	/**
	 * Remove any previous toast on screen
	 * @param logError If the function should log an error if dismiss is not possible
	 */
	public dismissToast(logError:boolean = false): Promise<boolean> {
		if (!this.toast) return new Promise(resolve => resolve(false));
		try {
			return this.toast.dismiss();
		} catch(e) {
			// if (logError) console.log(e);
			return new Promise(resolve => resolve(false));
		}
	}

	/** 
	 * Create an alert with button to confirm or cancel 
	 */
	async presentAlertConfirm(header: string, message: string, confirmText: string = "Confirmar", cancelText: string = "Cancelar"): Promise<boolean> {
		return new Promise(async resolve => {
			const alert = await this.alertController.create({
				header: header,
				message: message,
				buttons: [
					{
						text: cancelText,
						role: 'cancel',
						handler: () => {
							resolve(false);
						}
					}, {
						text: confirmText,
						role: 'confirm',
						handler: () => {
							resolve(true);
						}
					}
				]
			});
			return await alert.present();
		});
	}

	/**
	 * Create loading and return loading object
	 * @param message 
	 * @param backdrop User can dismiss the loading when clicking in background. Default is `false`
	 * @returns 
	 */
	 public createLoading(message: string, backdrop: boolean = false): Promise<HTMLIonLoadingElement> {
		return this.loading.create({
			message: message,
			backdropDismiss: backdrop

		}).then(loading => {
			return loading;
		});
	}

	/** Create toast */
	public presentToast(text: string, duration: number = 3000, position: 'bottom' | 'top' | 'middle' = 'bottom', status: 'success' | 'warning' | 'failed' = 'success'): Promise<void> {		

		// Remove any previous toast before create a new one
		this.dismissToast();

		return this.toastController.create({
			message: text,
			position: position as 'bottom' | 'top' | 'middle',
			duration: duration,
			cssClass: 'toastController',
			color: this.colorToast[status],
			icon: this.iconToast[status]

		}).then(toastX => {
			this.toast = toastX;
			return this.toast.present();
		});
	}
}