import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MenuController, NavController } from "@ionic/angular";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FirestoreService } from "src/app/services/firestore.service";
import { AuthService } from "src/app/services/auth.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";
import { StoreApp } from "src/app/services/interfaces.service";
import { parse, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/storage.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public  admin = false;
  fisterRender = true;
  message: any = null;
  logo: string = "";
  defaultLogo: string =
    "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";

  @ViewChild("popoverAvatar") popover;
  @ViewChild("popoverNotifications") notifications;
  @ViewChild("audioOption") audioPlayerRef: ElementRef;
  isOpen = false;
  isOpenNotifications = false;
  public openingHours: any = [];
  public isStoreOpen: boolean;
  public notification = localStorage.getItem("@Notification") || '0';

  constructor(
    private menu: MenuController,
    private navController: NavController,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private firebase: FirestoreService,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private storage: StorageService,
  ) {}

  ngOnInit() {
    this.fisterRender = true;
    this.requestPermission();
    this.getLogo();
    this.isAdmin()
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
  presentPopoverNotifications(e: Event) {
    this.notifications.event = e;
    this.isOpenNotifications = true;
  }

  public toggleMenu(): Promise<boolean> {
    return this.menu.toggle("main");
  }

  public store() {
    return this.navController.navigateRoot("store");
  }

  public exit() {
    this.auth.logout();
    localStorage.clear();
    this.storage.clearStorage()
    return this.navController.navigateRoot("login");
  }

  private async isAdmin() {
		return new Promise<boolean>((resolve) => {
			this.auth.isLogged().subscribe(user => {
				const storeId = user.uid;
				this.firebase.readData("users", storeId).subscribe((user) => {
					this.admin = user.role === "admin"
					resolve(user);
				});
			});
		});
	}

  async requestPermission() {
    try {
      let id = await this.auth.getStoreId();
      const messaging = getMessaging();
      const permission = await Notification.requestPermission()
      if (permission === "granted") {
        const currentToken = await getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
        if (currentToken) {
          this.upDateItem(id)
          this.listen()
          this.firebase.updateIntoDocumet("stores/", id, {
            tokenPush: currentToken,
          });
        } else {
          console.log("Nenhum token registrado");
        }
      } else {
        console.log("notificação negada");
      }
    } catch (error) {
      console.log("Erro ao gerar token", error);
    }
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.image,
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }

  upDateItem(id: string) {
    let initialDocumentCount: number;

    this.firebase.getDataUpdate("pedidos", "vendorId", id).pipe(take(1)).subscribe((data) => {
      initialDocumentCount = Array.isArray(data) ? data.length : 0;
    });

    this.firebase.getDataUpdate("pedidos", "vendorId", id).subscribe((data) => {
      const currentDocumentCount = Array.isArray(data) ? data.length : 0;
      const isNewDocument = currentDocumentCount > initialDocumentCount;

      if (isNewDocument && this.notification === '1') {
        this.playAudio();
      }

      initialDocumentCount = currentDocumentCount;
    });
  }

  playAudio() {
    if(this.fisterRender){
      return this.fisterRender = false;
    }
    return this.audioPlayerRef.nativeElement.play();
  }

  handleClick = () => {
    this.requestPermission();
  };

  private async getLogo() {
    const id = await this.auth.getStoreId();
    this.firebase.readData("stores", id).subscribe((data) => {
      this.logo = data && data.logo;
    });
  }
  isOpenStore() {
    this.auth.isLogged().subscribe((user) => {
      // console.log(user, user.uid);
      const id = user.uid;

      this.firebase
        .readData("stores/", id)
        .subscribe((storeData: StoreApp) => {
          this.openingHours = storeData.openingHours;
          const dataAtual = new Date();
          const dataString = format(dataAtual, 'yyyy-MM-dd');
          const data = parse(dataString, 'yyyy-MM-dd', new Date());
          const diaDaSemana = format(data, 'EEEE', { locale: ptBR });
          this.openingHours.forEach(element => {
            if(element.day.name === diaDaSemana){
              this.isStoreOpen = element.isClosed
              return;
            }
          });
        });
    });
  }

  salveActiveNotification(event) {
    if (event.target.checked === true) {
      localStorage.removeItem("@Notification");
      localStorage.setItem("@Notification", "1");
      this.notification = '1'
    } else {
      localStorage.removeItem("@Notification");
      localStorage.setItem("@Notification", "0");
      this.notification = '0'
    }
    this.cdr.detectChanges();
  }
}
