import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateMask]'
})
export class DateMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/\D/g, '');
    if (value.length <= 8) {
      input.value = this.formatDate(value);
    } else {
      input.value = this.formatDate(value.substr(0, 8));
    }
  }

  private formatDate(value: string): string {
    if (value.length >= 2) {
      value = `${value.substr(0, 2)}/${value.substr(2)}`;
    }
    if (value.length >= 5) {
      value = `${value.substr(0, 5)}/${value.substr(5)}`;
    }
    return value;
  }

}
