import { Injectable } from '@angular/core';
import { FirebaseOrder } from './interfaces.service';

@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	constructor() { }
	
	private days: string[] = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'];
	private abbreviatedDays: string[] = ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'];
	private months: string[] = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
	public deliveryTypes: {default: string, retirada: string, scheduled: string} = {
		default: 'Padrão',
		retirada: 'Retirada',
		scheduled: 'Programada'
	}
	
	/** 
	 * Update or insert an object in array based on an indentifier.
	 * It will update the existing array and also return the modified array.
	 * https://stackoverflow.com/a/49375465/7123439
	 * 
	 * @param array The array to be modified
	 * @param item The item to be updated or added in array
	 * @param identifier1 The first identifier to search in array
	 * @param identifier2 The second optional identifier to search in array
	 * @returns void
	*/
	public upsert(array: any[], item: any, identifier1:string|number, identifier2?:string|number): any[] {
		let i: number;
		if (!identifier2) {
			i = array.findIndex(_item => _item[identifier1] === item[identifier1]);
		} else {
			i = array.findIndex(_item => _item[identifier1] === item[identifier1] && _item[identifier2] === item[identifier2]);
		}

		if (i > -1) array[i] = item;
		else array.push(item);

		return array;
	}

	public sumOfProps(array: any[], prop: string): number {
		return array.reduce( (a, b) => {
			return a + b[prop];
		}, 0);
	}

	public formatPrice(value: string, locale: string = 'pt-BR', minFractionDigits: number = 2, maxFractionDigits: number = 2): string {
		if(!value) return ''	
		// converts to number and divides by 100
		const numberValue = Number(value.replace(/\D/g, '')) / 100;
		// format with dot and comma
		return numberValue.toLocaleString(locale, {
			minimumFractionDigits: minFractionDigits,
			maximumFractionDigits: maxFractionDigits
		});
	}

	public filterOrdersByWeek(orders: FirebaseOrder[]): FirebaseOrder[] {
		const currentDate = new Date();
		const oneWeekAgo = new Date(currentDate);
		oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
		oneWeekAgo.setHours(0, 0, 0, 0);
	
		const filteredOrders = orders.filter((pedido: FirebaseOrder) => {
			const orderDate = this.parseOrderDate(pedido.date);
			return orderDate >= oneWeekAgo && orderDate <= currentDate;
			// return orderDate
		});

		const sortedOrders = this.sortOrdersByDate(filteredOrders)
		return sortedOrders;
	}
	
	public parseOrderDate(dateString: string): Date {
		const year = Number(dateString?.substr(0, 4));
		const month = Number(dateString?.substr(4, 2)) - 1;
		const day = Number(dateString?.substr(6, 2));
		const hours = Number(dateString?.substr(8, 2));
		const minutes = Number(dateString?.substr(10, 2));
		const seconds = Number(dateString?.substr(12, 2));
		return new Date(year, month, day, hours, minutes, seconds);
	}
	
	public sortOrdersByDate(orders: FirebaseOrder[]): FirebaseOrder[] {
		return orders.sort((a: FirebaseOrder, b: FirebaseOrder) => {
			const dateA = this.parseOrderDate(a.date);
			const dateB = this.parseOrderDate(b.date);
			return dateB.getTime() - dateA.getTime();
		});
	}

	public formatCep(cep: string): string {
		return cep
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d)/, '$1-$2'); // adiciona um hífen após os primeiros 5 dígitos
	}

	formatCpfCnpj(value: string) {
    value = value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length <= 11) { // CPF
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else { // CNPJ
      value = value.replace(/(\d{2})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1/$2');
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return value;
  }

	public removeAllCharacters(value: string): string {
		return value.replace(/[^0-9]/g, '')
	}
}
